@import "./_variables.scss";

* {
    
    margin: 0;
    padding: 0;
}
.scrollhost {
    scrollbar-width: none;
   -ms-overflow-style: none;
 }
 
 .scrollhost::-webkit-scrollbar {
   display: none;
 }

.login{
    display: flex;
    margin: 10rem 1rem 1rem 1rem;
    justify-content: center;
}

.link-style{
    text-decoration: none !important;
    color:#11152D !important;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #11152D !important;
    }

}

.centerVertically{
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF6A1A; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF6A1A; 
}

// .navbar {
//   color: white;
//   font-size: 0.9em;
//   position: fixed;
//   width: 100%;
//   top: 0;
//   z-index: 999;
//   background: linear-gradient(to top, rgba(0, 0, 0.3, 0), rgba(0, 0, 0.3, 1));
//   &.scrolled {
//     background-color: var(--main-color);
//   }
  .containerDA {
    padding: 0.5em 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      img {
        height: 2.5em;
        margin-right: 8%;
      }
      display: flex;
      align-items: center;

      span {
        margin-right: 8%;
      }
    }
    .right {
          img {
        height: 4em;
        border-radius: 0.5em;
        height: 2.5em;
        width: 2.5em;
        object-fit: cover;
        cursor: pointer;
      }
      .profile {
        span {
          padding: 0.2em 0.8em;
          cursor: pointer;
        }
        .options {
          display: none;
        }
        &:hover {
          .options {
            display: flex;
            flex-direction: column;
            position: absolute;
            background-color: #F0F2F5;
            align-items: center;
            border-radius: 0.3em;
            font-size: small;
            width: 5rem;
          }
        }
      }

      .icons {
        margin: 0 0.9em;
      }
      display: flex;
      align-items: center;
    }
  }
  .container {
    padding: 0.5em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      img {
        height: 2.5em;
        margin-right: 8%;
      }
      display: flex;
      align-items: center;

      span {
        margin-right: 8%;
      }
    }
    .right {
          img {
        height: 4em;
        border-radius: 0.5em;
        height: 2.5em;
        width: 2.5em;
        object-fit: cover;
        cursor: pointer;
      }
      .profile {
        span {
          padding: 0.2em 0.8em;
          cursor: pointer;
        }
        .options {
          display: none;
        }
        &:hover {
          .options {
            display: flex;
            flex-direction: column;
            position: absolute;
            background-color: #F0F2F5;
            align-items: center;
            border-radius: 0.3em;
            font-size: small;
            width: 5rem;
          }
        }
      }

      .icons {
        margin: 0 0.9em;
      }
      display: flex;
      align-items: center;
    }
  }
// }

.notSelected{
  background-color:  #F0F2F5;
  color: #11152D;
  padding: 6px 8px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
}
.selected{
  background-color: #FF6A1A;
  color: #F0F2F5;
  padding: 6px 8px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
}

.imgSize {
  height: 2.5em;
  margin-right: 8%;
}

@media  (max-width: 800px) {
  .navbar {
  
    .container {
      .left {
      
        flex-direction: column;
        font-size: 16px;
        
      }
      
    }
  }
}
a{
  text-decoration: none !important;
  color:#FF6A1A !important
 
}
.Board {
  min-height: 250px;
}

@media  (max-width: 600px) {
  .vendorLeftBar {
      display: none;
  }
}



.loader {
  position: relative;
  width: 164px;
  height: 164px;
  margin: auto;
}
.loader::before , .loader::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #11152D;
  left: 50%;
  top: 50%;
  animation: rotate 1s ease-in infinite;
}
.loader::after {
width: 20px;
height: 20px;
background-color: #FF3D00;
animation: rotate 1s ease-in infinite, moveY 1s ease-in infinite ;
}

@keyframes moveY {
0% , 100% {top: 10%}
45% , 55% {top: 59%}
60% {top: 40%}
}
@keyframes rotate {
0% { transform: translate(-50%, -100%) rotate(0deg) scale(1 , 1)}
25%{ transform: translate(-50%, 0%) rotate(180deg) scale(1 , 1)}
45% , 55%{ transform: translate(-50%, 100%) rotate(180deg) scale(3 , 0.5)}
60%{ transform: translate(-50%, 100%) rotate(180deg) scale(1, 1)}
75%{ transform: translate(-50%, 0%) rotate(270deg) scale(1 , 1)}
100%{ transform: translate(-50%, -100%) rotate(360deg) scale(1 , 1)}
}
  