* {
  line-height: 1;
}

.header {
  height: 2.8em;
  background-image: linear-gradient(180deg, #c28a07 -21.11%, #f46d25 153.33%);
}

.container-head {
  position: relative;
  text-align: center;
  background-color: #122f4b;
  .backgroundImage {
    background-image: linear-gradient(315deg, #e6ed53, #43c4ed);
    opacity: 0.3;
    height: 85vh;
    object-position: 0% 15%;
    object-fit: cover;
  }
}
.paragraphs {
  font-size: 15px;
  font-weight: 450;
  position: absolute;
  text-align: left;
  top: 55%;
  left: 60%;

  right: 56px;
  color: #fff;
}
.textover {
  color: #fff;
  font-size: 7.5vh;
  position: absolute;
  top: 30%;

  font-weight: 900;
  left: 60%;
  right: 56px;
  text-align: left;
}
.colorChangeSpan {
  background: #f46d25;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headerblock {
  // display: flex;
  overflow-y: hidden;
  .topMenu {
    position: absolute;
    top: 2%;
    left: 56px;
    width: 94%;
    .topMenuContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menuItems {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
      }
      .leftMenu {
        width: 25%;
        display: flex;
        justify-content: space-between;
      }
      .middleMenu {
        img {
          height: 35px;
        }
      }
      .rightMenu {
        width: 25%;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          width: 100px;
          height: 34px;
          padding: 6px 2px 6px 2px;
          font-size: 13px;
          font-weight: 700;

          border: 0;
          border-radius: 4px;
          color: #fff;
          background: #f46d25;
          cursor: pointer;
        }
      }
    }
  }
  .mobileTopMenu {
    visibility: hidden;
    position: absolute;
  }

  img {
    width: 100%;
    height: auto;
  }

  .button {
    position: absolute;
    top: 70%;
    left: 60%;

    .buttonOne {
      width: 133px;
      height: 40px;
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      top: 421px;
      left: 69px;
      border: 0;
      border-radius: 4px;
      margin-right: 15px;
      background: #f46d25;
      cursor: pointer;
    }
    .buttonTwo {
      width: 133px;
      height: 40px;
      font-size: 15px;
      font-weight: 700;
      color: #0a1d2f;
      top: 421px;
      left: 69px;
      border: 0;
      border-radius: 4px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
}

.logisticNetwork {
  // overflow-y: hidden;
  background-image: linear-gradient(to right, #00121f, #000407);
  backdrop-filter: opacity(0.5);
  padding: 10% 56px 10% 56px;

  // height: 90vh;
  .textover-1 {
    color: #fff;
    font-size: 300%;

    text-align: left;
  }

  .subheading {
  }
  .gridCard-1 {
    .cardLogisticsNetwork {
      width: 100%;
      min-height: 100px;
      text-align: center;
      border: 1px solid rgba(220, 220, 220, 0.616);
      border-radius: 8px;
      padding: 20px 10px;
      background-color: #13232f; /* Fallback color */
      background-color: #13232f; /* Black w/opacity/see-through */

      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
    .cardLogisticsNetwork:hover {
      transform: scale(1.1);
      transition: all 1s;
      z-index: 999 !important;
    }
  }
}
.fontSizePara {
  font-weight: 400;
}
.quotes {
  padding: 8% 15% 0 10%;
  font-weight: 400;
  line-height: 1.4;
  font-style: italic;
  font-size: 25px;
  color: #ffffff75;
}

.heading-text {
  font-size: 300%;
  font-weight: 800;
}
.heading-text-2 {
  font-size: 450%;
  font-weight: 900;
}
.saksham {
  padding: 10% 56px 10% 10%;
  background-color: #13232f;
  .imagesOfStation {
    background-size: cover;
    width: 30vh;
    border-radius: 8px;
    height: 20vh;
  }
  .saksham-text {
    margin-top: 20%;
    text-align: right;
    .paragraph {
      position: inline;
      font-size: 15px;
      font-weight: 400;
      color: #fff;
    }
  }
}

.map-section {
  padding: 10% 56px 10% 15%;
  background-image: linear-gradient(to right, #00121f, #000407);
  .numbers {
    margin-top: 10px;
    text-align: left;
  }
}

.whyChooseUs {
  padding: 10% 10% 10% 10%;
  background-color: #13232f;
  .iconsAndText {
    min-height: 200px;
    background-color: #00121f6c;
    padding: 10%;
    border: 1px solid #ffeeff5d;
    border-radius: 8px;
    img {
      filter: invert(50%) sepia(46%) saturate(7344%) hue-rotate(3deg)
        brightness(101%) contrast(107%);
      fill: #f46d25 !important;
      width: 50px;
      margin-bottom: 10px;
    }
    text-align: center;
    margin-top: 10%;
    // margin: auto;
  }

  .iconsAndText:hover {
    transform: scale(1.05);
    transition: all 0.4s;
    z-index: 999 !important;
  }
}

.talkToExpert {
  padding: 10% 10%;
  background-image: linear-gradient(to right, #00121f, #000407);

  #contactForm {
    margin-top: 30px;
    ::placeholder {
      color: #fff;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12-18 */
      color: #fff;
      font-weight: 800;
    }
    .submitButton {
      display: flex;
      width: 100%;
      justify-content: end;
    }
    input,
    textarea {
      width: 100%;
      font-size: 20px;
      border-radius: 6px;
      padding: 18px 10px;
      border: 0px;
      background-color: #13232f;
      color: #fff;
    }
  }
  button {
    margin-top: 1%;
    width: 100px;
    height: 34px;
    padding: 6px 2px 6px 2px;
    font-size: 13px;
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    color: #fff;
    background: #f46d25;
    cursor: pointer;
  }
}

.faq {
  background-color: #13232f;
  padding: 10% 10% 10% 10%;
  .iconsFlex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;
  }

  .questionAnswer {
    padding: 27px 20px;
    border: 1px solid #a7aaae;
    margin-bottom: 10px;
    border-radius: 3px;
    // padding: 10px;
    background-color: #00121fa4;
  }
  .questionAnswer-Selected {
    padding: 27px 20px;
    border: 1px solid #a7aaae;
    margin-bottom: 10px;
    border-radius: 3px;
    // padding: 10px;
    background-color: #0026419a;
    .h3Tag {
      font-size: 20px;
      color: #f46d25;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .h3Tag {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .h5Tag {
    color: #e2e2e2;
    font-size: 15px;
    font-weight: 400;
  }

  hr {
    color: #f46d25;
    margin: 10px 0;
  }
}

.footerHomePage {
  position: relative;
  text-align: right;
  // object-fit: cover;
  max-height: 650px;
  overflow: hidden;
  background-image: linear-gradient(315deg, #131312, #252525);
  img {
    background-size: cover;
    opacity: 0.45;

    width: 100%;
    height: auto;
  }
  .rightText {
    position: absolute;
    top: 30%;
    right: 10%;
    font-weight: 400;
    color: #fff;
  }
  .contact {
    position: absolute;
    top: 45%;
    right: 10%;
    color: #fff;
    font-size: 20px;
  }
  .bottom-text {
    position: absolute;
    top: 95%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);

    font-size: 12px;
  }
}
.anchorTags {
  text-decoration: none !important;
  color: #fff !important;
}
.videoSection {
  padding: 10%;
  text-align: center;
  background-image: linear-gradient(to right, #00121f, #000407);
  video {
    border-radius: 8px;
    width: 95%;
    height: auto;
  }
}

@media (max-width: 800px) {
  .container-head {
    height: 100vh;

    .backgroundImage {
      object-position: 20% 0%;
      height: 100vh;
    }
    .button {
      left: 5% !important;
      // right: 0% !important;
      transform: translate (-50%, -50%) !important;
    }
  }
  .videoSection {
    .quotes {
      font-size: 15px;
      padding: 5%;
    }
    video {
      margin-top: 10%;
    }
  }
  .sideMenu {
    position: fixed;
    right: 0%;
    bottom: 0;
    width: 70%;
    height: 100%;
    opacity: 0.95;
    // filter: blur(8px);
    background-color: #00121f;
    z-index: 998;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    ul{
      margin-top: 10%;
      padding: 10%;
    }

    li{
      list-style: none;
      margin-bottom: 5%;
    }
    button{
      width: 100px;
      height: 34px;
      padding: 6px 2px 6px 2px;
      font-size: 13px;
      font-weight: 700;

      border: 0;
      border-radius: 4px;
      color: #fff;
      background: #f46d25;
      cursor: pointer;
    }
  }
  .mobileTopMenu {
    visibility: visible !important;
    // position: absolute;
    display: flex;
    padding: 3%;
    justify-content: space-between;
    // align-items: center;
    top: 1%;
    width: 100%;
    img {
      width: 15vh;
    }
    // left: 50%;
    // transform: translate(-50%, -50%);
    .button {
      left: 50%;
      right: 0%;
      transform: translate (-50%, -50%);
    }
    .nav-icon {
      color: #fff;
      z-index: 999;
    }
  }

  .headerblock {
    // width: 89vh;
    overflow: hidden;
  }
  .logisticNetwork {
  }
  .topMenuContainer {
    visibility: hidden;
  }
  .textover {
    color: #fff;
    top: 20%;
    left: 20%;
    font-size: 200%;
    font-weight: 900;
    text-align: center !important;
  }
  .logisticNetwork,
  .saksham,
  .map-section,
  .whyChooseUs,
  .talkToExpert,
  .faq {
    padding: 30% 12%;
  }
  .imagesOfStation {
    width: 100% !important;
    height: 100% !important;
  }
  .whyChooseUs {
    .heading-text-2 {
      margin-bottom: 6% !important;
    }
  }
  .heading-text-2 {
    font-size: 300%;
  }
  .textover-1 {
    color: #fff;
    font-size: 200%;
  }
  .iconsAndText {
    margin-top: 4% !important;
  }
  .heading-text {
    font-size: 163%;
    font-weight: 700;
  }
  .paragraphs {
    // font-size: 12px;
    top: 45%;
    left: 20%;
    text-align: center !important;
  }
  .gridCard {
    top: 300px;
    margin-top: 50px;
  }
  .button {
    left: 20%;
    right: 0px;
    .buttonOne {
      margin-bottom: 10px;
    }
  }
  .saksham {
    img {
      width: 100%;
    }
    .heading-text-2,
    .paragraph {
      text-align: center !important;
    }
  }
  .map-section {
    img {
      width: 100%;
    }
  }
  .footerHomePage {
    .contact {
      top: 60%;
      font-size: 15px;
      font-weight: 700;
    }
    .bottom-text {
      font-size: 8px;
      left: 50%;
      right: auto;
      font-weight: 400;
    }
  }
}

// background: linear-gradient(270.59deg, #FF0000 26.86%, #FFF300 125.21%);
